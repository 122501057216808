import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import { useStore } from "vuex";
import RoutePath from "@/commons/RoutePath";
import OrganizationRegisterInteractor from "@/domain/usecases/OrganizationRegisterInteractor";
import DIContainer from "@/core/DIContainer";
import { useForm } from "vee-validate";
import TransformUtil from '@/commons/TransformUtil';
export default defineComponent({
    name: "OrganizationRegisterConfirm",
    components: {
        Breadcrumb: Breadcrumb
    },
    setup: function () {
        var store = useStore();
        var router = useRouter();
        var organizationRegisterInteractor = DIContainer.instance.get(OrganizationRegisterInteractor);
        var _a = useForm(), isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit;
        var state = reactive({
            listBreadcrumb: [
                {
                    route: '/', name: "TOP"
                },
                {
                    route: '', name: "基本情報入力"
                }
            ],
            profileForm: {}
        });
        onMounted(function () {
            var companyRegisterForm = localStorage.getItem("companyRegisterForm");
            if (companyRegisterForm) {
                state.profileForm = Object.assign(state.profileForm, JSON.parse(companyRegisterForm));
            }
        });
        var goSendMail = handleSubmit(function () {
            return organizationRegisterInteractor.register(state.profileForm).then(function (result) {
                router.push({
                    path: "./send_mail",
                    query: {
                        organization_id: result.data.organization_id,
                        organization_member_id: result.data.organization_member_id
                    }
                });
            });
        });
        function goBack() {
            router.back();
        }
        function hidePassword(values) {
            if (!values) {
                return "";
            }
            var cache = "";
            for (var i = 0; i < values.length; i++) {
                cache += "*";
            }
            return cache;
        }
        function formatPhoneNumber(str) {
            return TransformUtil.formatPhoneNumber(str);
        }
        onMounted(function () {
            var organizationResgisterData = store.state.organizationResgisterData;
            if (!organizationResgisterData.email) {
                return router.push(RoutePath.LOGIN);
            }
            Object.assign(state.profileForm, organizationResgisterData);
        });
        return {
            state: state,
            isSubmitting: isSubmitting,
            goSendMail: goSendMail,
            goBack: goBack,
            hidePassword: hidePassword,
            formatPhoneNumber: formatPhoneNumber
        };
    }
});
